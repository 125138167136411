<template>

  <div class="mb-4">
    <Table name="Invoice Lists" :url="table.url" :headers="table.headers" :getUpdate="table.getUpdate"
           :searchable="true" :selectable="true">
      <template v-slot:company="props">
        <span v-if="props.row.company === null">--</span>
        <div v-else>
          <router-link
              :to="{name: 'customer_profile', params: {slug: props.row.company.slug}}">
                    <span class="rounded-circle bg-soft-secondary text-secondary mx-1 px-2">
          {{ (props.row.company.name.slice(0, 1)) }}
        </span>
            <span>
          {{ (props.row.company.name) }}
        </span>
          </router-link>
        </div>
      </template>

      <template v-slot:file="props">
      <div class="big_icon_on_hover link-info text-truncate" @click="downloadInvoice(props.row.file)">
        {{ getFileName(props.row.file) }}
        <i class="bx bxs-download text-secondary fs-16 ms-1"></i>
      </div>
      </template>

      <template v-slot:order="{row: invoice}">
        <span v-if="invoice.order.child_type === 'container_order'"
              class="badge badge-soft-secondary fs-12">
                <router-link
                    :to="{
                  name: 'orders_container_detail',
                  params: {id: invoice.order.order_number },
                   query: {invoice: invoice.id || 'unknown'}
                }">
                    {{ invoice.order.order_number }}
                </router-link>
        </span>
        <span v-else-if="invoice.order.child_type === 'wagon_order'"
              class="badge badge-soft-secondary fs-12">
                <router-link
                    :to="{
                  name: 'orders_wagon_detail',
                  params: {id: invoice.order.order_number },
                   query: {invoice: invoice.id || 'unknown'}
                }">
                    {{ invoice.order.order_number }}
                </router-link>
        </span>
        <span v-else-if="invoice.order.child_type === 'wagon_empty_order'"
              class="badge badge-soft-secondary fs-12">
                <router-link
                    :to="{
                      name: 'orders_empty_wagon_detail',
                      params: {id: invoice.order.order_number },
                      query: {invoice: invoice.id || 'unknown'}
                }">
                    {{ invoice.order.order_number }}
                </router-link>
        </span>
      </template>

      <template v-slot:total_cost="props">
      <span v-if="props.row.total_cost">
        ${{
          parseFloat(Math.round(parseFloat(props.row.total_cost) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }}
      </span>
        <span v-else>-</span>
      </template>

      <template v-slot:paid_status="props">
        <span v-if="props.row.paid_status === 'paid'" class="badge bg-success">Paid</span>
        <span v-else-if="props.row.paid_status === 'unpaid'" class="badge bg-danger">Unpaid</span>
        <span v-else>-</span>
      </template>

      <template v-slot:actions="props">
        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <div class="dropdown">
            <b-button variant="light" data-bs-toggle="dropdown" class="px-2 py-1">
              <i class="ri-pencil-fill align-bottom ps-1"></i>
            </b-button>
            <div class="dropdown-menu dropdown-menu-end">

              <div v-if="props.row.paid_status === 'unpaid'"
                   class="dropdown-item cursor-pointer"
                   @click="changeInvoiceStatus(props.row, 'paid')"
              >
                <h6 class="my-0 py-0 d-inline align-middle">
                  Change status to Paid
                </h6>
                <i class="bx bx-check-double text-success fs-20 align-middle ms-1"></i>
              </div>

              <div v-else-if="props.row.paid_status === 'paid'"
                   class="dropdown-item cursor-pointer"
                   @click="changeInvoiceStatus(props.row, 'unpaid')"
              >
                <i class="ri-file-paper-line me-2 my-0"></i>
                <h6 class="my-0 py-0 d-inline">
                  Change status to Unpaid
                </h6>
              </div>

            </div>
          </div>
          <b-button @click="current_invoice_id = props.row.id" variant="light" href="#invoice_delete_modal"
                    data-bs-toggle="modal">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>
      </template>
    </Table>
  </div>
  <InvoiceDeleteModal @deleted="table.getUpdate = !table.getUpdate" :invoiceId="current_invoice_id"/>
</template>

<script>
import Table from "@/components/tables/table.vue";
import axios from "axios";
import Swal from "sweetalert2";

import InvoiceDeleteModal from './modals/InvoiceDelete.vue'

export default {
  name: "create",
  data() {
    return {
      order_number: this.$route.params.order_number,
      table: {
        url: '/invoice/list/',
        getUpdate: false,
        headers: [
          {
            field: 'id',
            label: 'ID',
            align: 'center',
            visible: false,
            excel_data: (invoice) => {
              return invoice.id
            }
          },
          {
            field: 'order',
            label: 'ORDER NUMBER',
            align: 'center',
            excel_data: (invoice) => {
              return invoice.order ? invoice.order.order_number : '-'
            }
          },
          {
            field: 'company',
            label: 'COMPANY',
            align: 'center',
            excel_data: (invoice) => {
              return invoice.company ? invoice.company.name : '-'
            }
          },
          {
            field: 'file',
            label: 'FILE',
            align: 'center',
            excel_data: (invoice) => invoice.file
                ? {
                  text: 'FILE',
                  hyperlink: invoice.file, // Actual link URL,
                  width: 15
                }
                : '',
            searchable: false,
            maxWidth: '350px'
          },
          {
            field: 'paid_status',
            label: 'STATUS',
            align: 'center',
            excel_data: (invoice) => {
              return invoice.paid_status
            }
          },
          {
            field: 'date',
            label: 'DATE',
            align: 'center',
            searchType: 'date',
            excel_data: (invoice) => {
              return invoice.date
            }
          },
          {
            field: 'total_cost',
            label: 'TOTAL COST',
            align: 'center',
            excel_data: (invoice) => {
              return invoice.total_cost
            }
          },
          {
            field: 'actions',
            label: 'ACTIONS',
            align: 'center'
          }
        ]
      },
      current_invoice_id: 0
    }
  },
  methods: {
    getFileName(file) {
      if (!file) return '-'
      return file.split('/').pop()
    },
    downloadInvoice(file) {
      if (!file) return alert("Couldn't find the file")
      fetch(file)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = file.toString().split('/')[file.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },

    async changeInvoiceStatus(invoice, invoiceNewStatus) {
      try {
        await axios.post('/invoice/invoice_status/' + invoice.id + '/', {
          paid_status: invoiceNewStatus
        })
        this.table.getUpdate = !this.table.getUpdate
        await Swal.fire({
          title: 'Success',
          html: `Invoice status has been changed <br> from <span class="text-danger text-capitalize text-decoration-line-through">${invoice.paid_status}</span> to <span class="text-success text-capitalize fw-semibold">${invoiceNewStatus}</span>`,
          icon: 'success',
        })
      } catch {
        await Swal.fire({
          title: 'Error',
          text: 'Something went wrong',
          icon: 'error',
        })
      }
    }
  },
  components: {
    Table,
    InvoiceDeleteModal
  },
}
</script>

<style scoped>
.big_icon_on_hover {
  transition: 0.2s;
}

.big_icon_on_hover:hover {
  cursor: pointer;
}

.big_icon_on_hover:hover i {
  transform: scale(1.5);
  transition: 0.2s;
}
</style>